import { defineStore} from "pinia";
import { Agent, AgentOption } from "@/models/agent/agent";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";

export const agentStore = defineStore("agent", {
  state: () => ({
    agents: null as Agent[] | null,
    agentSelected: undefined as unknown as Agent,
    isLoading: false,
    agentOptions: [] as AgentOption[]
  }),

  getters: {
    getAgents: (state) => state.agents,
    getAgent: (state) => state.agentSelected,
    getLoading: (state) => state.isLoading,
  },

  actions: {
    loadAgents(): any {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).loadAgents()
          .then(value => {
              if (value.data) {
                let data = [] as Agent[];
                data = value.data;
                data.sort(function (a, b) {
                  const A = new Date(a['created_at']);
                  const B = new Date(b['created_at']);
                  return A < B ? 1 : -1;
                });
                this.agents = data
                this.formatAgents(data)
              }
          })
          .catch(error => {
            console.error('Failed to load agents', error);
            throw error;
          });
    },
    formatAgents(agents: Agent[]) {
      agents.map((agent: Agent) => {
        this.agentOptions.push({
          name: agent.name,
          id: agent.agent_id,
        });
      });
    },
    addAgent(payload: Agent) {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).createAgent(payload)
        .then((value) => {
          if (value.data) {
            this.agents?.unshift(value.data)
            this.isLoading = false
            return value
          }
        });
    },
    updateAgent(payload: Agent) {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).updateAgent(payload)
        .then((value) => {
          const agent = this.agents?.find(
            (element) => element.agent_id == payload?.agent_id,
          );
          if (agent) {
            agent.name = payload.name;
            agent.mobile = payload.mobile;
            agent.function = payload.function;
            agent.service_id = payload.service_id;
            agent.sex = payload.sex;
            const index = this.agents?.indexOf(agent);
            if(this.agents && index) this.agents[index] = agent;
          }
          this.isLoading = false
          return value
        });
    },
    setAgent(payload: Agent) {
      this.agentSelected = payload
    },
    setLoading() {
      this.isLoading = true
    },
  }
})