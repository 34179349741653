import { defineStore} from "pinia";
import { BirthDeclaration } from "@/models/declaration/birth-declaration";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";

export const useBirthStore = defineStore("birth", {
  state: () => ({
    births: null as BirthDeclaration[] | null,
    birthSelected: undefined as unknown as BirthDeclaration,
  }),

  getters: {
    getBirths: (state) => state.births,
    getBirth: (state) => state.birthSelected,
  },

  actions: {
    sendBirthDeclaration(payload: Array<BirthDeclaration>): Promise<any> {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).sendBirthDeclaration(payload)
        .then(value => {
          if (value.data) {
            this.births?.unshift(value.data[0]);
            return value.data[0]
          }else return null
        })
    },
    loadBirthDeclarations(payload) {
      ProcessManagerService.getInstance(useAuthStore().getAccessToken).loadBirthDeclarations(payload)
          .then(value => {
              if (value.data) {
                const data = value.data;
                data.sort(function (a, b) {
                  const A = new Date(a['created_at']);
                  const B = new Date(b['created_at']);
                  return A < B ? 1 : -1;
                });
                this.births = data
              }
          })
    },
    setBirth(payload: BirthDeclaration) {
      this.birthSelected = payload
    },
  }
})