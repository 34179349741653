<template>
	<div class="container-fluid">
		<div class="container">
			<div class="pt-4 pb-0">
				<h1 class="page-title">Bienvenue dans votre espace dédié</h1>
			</div>
			<div class="declarations-container">
				<span
					class="espace-title"
					style="margin-bottom: 2rem"
					>Gestion des naissances</span
				>
			</div>
			<div class="declarations-container">
				<DeclarationsList :cards="naissanceList" />
			</div>
			<div class="espace-border"></div>
			<div class="declarations-container">
				<span
					class="espace-title"
					style="margin-bottom: 2rem"
					>Gestion des décès</span
				>
			</div>
			<div class="declarations-container">
				<DeclarationsList :cards="decesList" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import DeclarationsList from "@/components/espace-partenaires/declarations-list/declarations-list.vue";
	import { useAuthStore } from "@/store/modules/auth";
  import { useBirthStore } from "@/store/modules/birth";
  import { useDeathStore } from "@/store/modules/death";
	import { agentStore } from "@/store/modules/agent";
	import { espacePartenaireStore } from "@/store/modules/espace-partenaire";
	import { constantsMixin } from "@/mixins/constants.mixins";
	export default defineComponent({
		components: { DeclarationsList },
		mixins: [constantsMixin],
		data() {
			return {
				authStore: useAuthStore(),
				agentStore: agentStore(),
        birthStore: useBirthStore(),
        deathStore: useDeathStore(),
				partnerStore: espacePartenaireStore(),
			};
		},
		async mounted() {
			let payload = {
				tenant: this.authStore.getUserInfo.tenant,
				health_center_code: this.authStore.getUserInfo.health_center_code,
			};
			if(!this.agentStore.getAgents) this.agentStore.loadAgents();
			if(!this.birthStore.getBirths) this.birthStore.loadBirthDeclarations(payload);
			if(!this.deathStore.getDeaths) this.deathStore.loadDeathDeclarations(payload);
		},
	});
</script>

<style lang="scss" src="./accueil-page.scss" scoped />