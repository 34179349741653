import { defineStore} from "pinia";
import { DeathDeclaration } from "@/models/declaration/death-declaration";
import { ProcessManagerService } from "@/services/process-manager.service";
import { useAuthStore } from "@/store/modules/auth";

export const useDeathStore = defineStore("death", {
  state: () => ({
    deaths: null as DeathDeclaration[] | null,
    deathSelected: undefined as unknown as DeathDeclaration,
  }),

  getters: {
    getDeaths: (state) => state.deaths,
    getDeath: (state) => state.deathSelected,
  },

  actions: {
    sendDeathDeclaration(payload: Array<DeathDeclaration>) : any {
      return ProcessManagerService.getInstance(useAuthStore().getAccessToken).sendDeathDeclaration(payload)
            .then(value => {
                if (value.data) {
                  this.deaths?.unshift(value.data[0])
                  return value.data[0]
                }else return null
            })
    },
    loadDeathDeclarations(payload) {
      ProcessManagerService.getInstance(useAuthStore().getAccessToken).loadDeathDeclarations(payload)
          .then(value => {
              if (value.data) {
                const data = value.data;
                data.sort(function (a, b) {
                  const A = new Date(a['created_at']);
                  const B = new Date(b['created_at']);
                  return A < B ? 1 : -1;
                });
                this.deaths = data
              }
          })
    },
    setDeath(payload: DeathDeclaration) {
      this.deathSelected = payload
    },
  }
})