import { ApiResponse } from "@/models/http/ApiResponse";
import { HttpBaseService } from "./httpBase.service";
import { BirthDeclaration } from "../models/declaration/birth-declaration";
import { processManagerApiUrl } from "@/app.config"
import { DeathDeclaration } from "@/models/declaration/death-declaration";
import { Agent } from "@/models/agent/agent";
import { HealthCenter } from '@/models/health-center/health-center';
import { Service } from "@/models/service/service";

export class ProcessManagerService extends HttpBaseService{

    private static classInstance?: ProcessManagerService
    static apiBaseUrl = processManagerApiUrl;

    constructor(token:string) {
        super(token, ProcessManagerService.apiBaseUrl)
    }

    public static getInstance(token:string): ProcessManagerService {

        if(!this.classInstance) {
            this.classInstance = new ProcessManagerService(token)
        }

        return this.classInstance
    }

    public async sendBirthDeclaration(payload: Array<BirthDeclaration>): Promise<ApiResponse<BirthDeclaration>> {
        const apiResponse = new ApiResponse<BirthDeclaration>();
        return this.instance.post('/birth/create', payload)
          .then(response => {
              apiResponse.data = response.data;
              return apiResponse;
          })
          .catch(error => {
            apiResponse.apiError = error.response.data;
            return apiResponse;
          });
    }

    public async sendDeathDeclaration(payload: Array<DeathDeclaration>): Promise<ApiResponse<DeathDeclaration>> {
        const apiResponse = new ApiResponse<DeathDeclaration>();
        return this.instance.post('/death/create', payload)
          .then(response => {
              apiResponse.data = response.data;
              return apiResponse;
          })
          .catch(error => {
            apiResponse.apiError = error.response.data;
            return apiResponse;
          });
    }

    public async createAgent(payload: Agent): Promise<ApiResponse<Agent>> {
        const apiResponse = new ApiResponse<Agent>();
        return this.instance.post('/agent/create', payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            });
      }

      public async updateAgent(payload: Agent): Promise<ApiResponse<Agent>> {
        const apiResponse = new ApiResponse<Agent>();
        return this.instance.put('/agent/update', payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            });
      }

    public async createService(payload: Service): Promise<ApiResponse<Service>> {
        const apiResponse = new ApiResponse<Service>();
        return this.instance.post('/department/create', payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            });
      }

    public async loadServices() : Promise<ApiResponse<Service[]>>{
        return this.instance.get(`/departments/tenant`)
            .then(response => {
                const apiResponse = new ApiResponse<Service[]>()
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(() => {
                return new ApiResponse<Service[]>()
            })
      }


    public async loadBirthDeclarations(payload: object) : Promise<ApiResponse<BirthDeclaration[]>>{
      return this.instance.post('/birth/search', payload)
          .then(response => {
              const apiResponse = new ApiResponse<BirthDeclaration[]>()
              apiResponse.data = response.data;
              return apiResponse;
          })
          .catch(() => {
              return new ApiResponse<BirthDeclaration[]>()
          })
    }

    public async loadDeathDeclarations(payload: object) : Promise<ApiResponse<DeathDeclaration[]>>{
      return this.instance.post('/death/search', payload)
          .then(response => {
              const apiResponse = new ApiResponse<DeathDeclaration[]>()
              apiResponse.data = response.data;
              return apiResponse;
          })
          .catch(error => {
                console.log(error)
              return new ApiResponse<DeathDeclaration[]>()
          })
    }

    public async loadAgents() : Promise<ApiResponse<Agent[]>>{
        return this.instance.get('/agents')
            .then(response => {
                const apiResponse = new ApiResponse<Agent[]>()
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                console.log(error)
                return new ApiResponse<Agent[]>()
            })
      }

    public async getHealthCenterInformations(health_center_code: string) : Promise<ApiResponse<HealthCenter>>{
        const apiResponse = new ApiResponse<HealthCenter>()
        return this.instance.get(`/health-center/get-health-center/${health_center_code}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response?.data;
                return apiResponse;
            })
      }

}