<template>
	<div class="page-container">
		<div>
			<div class="title-padding m-0">
				<h1 class="espace-header">Liste des déclarations de décès</h1>
			</div>
			<div>
				<death-registration />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from "vue";
	import DeathRegistration from "@/components/espace-partenaires/pages-block/death-registration/death-registration.vue";
	import { useDeathStore } from "@/store/modules/death";
	import { useAuthStore } from "@/store/modules/auth";
	import { agentStore } from "@/store/modules/agent";
	export default defineComponent({
		name: "ListeDeces",
		components: {
			DeathRegistration,
		},
		data() {
			return {
				deathStore: useDeathStore(),
				authStore: useAuthStore(),
				agentStore: agentStore(),
			};
		},
		methods: {
			loadData() {
				let payload = {
					tenant: this.authStore.getUserInfo.tenant,
					health_center_code: this.authStore.getUserInfo.health_center_code,
				};
				if(!this.agentStore.getAgents) this.agentStore.loadAgents();
				if(!this.deathStore.deaths) this.deathStore.loadDeathDeclarations(payload);
			}
		},
		beforeMount() {
			this.loadData()
		},
	});
</script>
